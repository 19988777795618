import React from 'react';
import ReactDOM from 'react-dom';

import VideoCover from 'react-video-cover';

import styles from './stylesheet.scss';

if (process.env.NODE_ENV !== 'production')
   console.log('Looks like we are in development mode!');


ReactDOM.render(
  <div className={styles.container}>

    <div className={styles.vimeowrapper}>
      <iframe src={'https://player.vimeo.com/video/299216569?background=1&muted=1&autoplay=1&loop=1&byline=0&title=0#t=20s'}
          frameBorder="0" webkitallowfullscreen="true"  mozallowfullscreen="true"  allowFullScreen={true}></iframe>
    </div>

    <header>
      <h1>4669201609</h1>
      <h2>new media art / experimental music and live coding</h2>
    </header>

    <footer>
      www.nguyenminhduc.de / 4669201609 &copy; 2018 MONTREAL / BRUSSELS / DRESDEN<br/>
    info : artwork QSS @ objekt klein a, Dresden, Germany<br/>
    website by<a
      href="https://www.pinkbeton.com"
      style={{
        marginLeft: "1vmin",
        textDecoration: "none",
        color: "pink"
      }}
      target="_blank"
    >
      &#9751; pinkbeton
    </a>
    </footer>

  </div>,
  document.getElementById('app')
);



if (process.env.NODE_ENV !== 'production')
  module.hot.accept();
